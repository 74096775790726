import {ERoute} from "apps/sliderary/configs/ERoute";
import {EIconNames, EPermissions, EProfileMenuItems} from "./enums";

export const PROFILE_MENU_PATH_DATA_MAP: Record<
  EProfileMenuItems,
  {text: string; iconName: EIconNames; linkTo?: ERoute}
> = {
  [EProfileMenuItems.PROFILE]: {
    text: "Профиль",
    iconName: EIconNames.PROFILE,
    linkTo: ERoute.PROFILE,
  },
  [EProfileMenuItems.UPLOADED]: {
    text: "Загрузки",
    iconName: EIconNames.UPLOAD,
    linkTo: ERoute.UPLOADED,
  },
};

export const PERMISSION_FIELD_MAP = {
  canUpload: EPermissions.UPLOAD,
  filterReviewed: EPermissions.REVIEWED_FILTER,
  canViewUserRating: EPermissions.VIEW_USER_RATING,
  canDeletePresentation: EPermissions.DELETE_PRESENTATION,
  canExport: EPermissions.EXPORT,
};
