import client from "api/client";
import type {AsyncAxiosResponse} from "../../../types/common";
import {type Components} from "../../../types/client";

enum EMainWindowUrl {
  TABS = "main/tabs",
}

export async function getTabs(): AsyncAxiosResponse<
  Components.Schemas.MWTab[],
  any
> {
  return await client.get(EMainWindowUrl.TABS);
}
