import React from "react";
import ReactDOM from "react-dom/client";
import {App} from "./apps/sliderary";
import {isCorporate} from "common/constants/core";

const element = document.getElementById("root") as Element;

const root = ReactDOM.createRoot(element);

element.className = `${isCorporate ? "light-corporate" : "educational"}-theme`;

console.log(process.env);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
