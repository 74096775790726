import {type ReactNode, createContext, useEffect, useState} from "react";
import {ETheme} from "../apps/sliderary/configs/ETheme";
import {isEducational} from "common/constants/core";

interface ThemeProvider {
  children: ReactNode;
}

export interface IThemeContext {
  theme: ETheme;
  applyTheme: (theme: ETheme) => void;
}

const ThemeContext = createContext<IThemeContext>({
  theme: isEducational
    ? ETheme.EDUCATIONAL_THEME
    : ETheme.LIGHT_CORPORATE_THEME,
  applyTheme: () => {},
});
export const ThemeContextProvider = ({children}: ThemeProvider) => {
  const [theme, setTheme] = useState(
    isEducational ? ETheme.EDUCATIONAL_THEME : ETheme.LIGHT_CORPORATE_THEME,
  );

  const element = document.getElementById("root") as Element;
  const html = document.getElementsByTagName("html")[0];

  useEffect(() => {
    html?.setAttribute(
      "class",
      theme === ETheme.EDUCATIONAL_THEME ? "educational" : "corporate",
    );

    theme !== ETheme.EDUCATIONAL_THEME &&
      html?.setAttribute(
        "color-scheme",
        theme === ETheme.LIGHT_CORPORATE_THEME ? "light" : "dark",
      );
  }, [theme]);

  const applyTheme = (newTheme: ETheme) => {
    element.className = newTheme;

    setTheme(newTheme);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    <ThemeContext.Provider value={{theme, applyTheme} as IThemeContext}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
