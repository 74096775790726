import {type Components, type Paths} from "../../../types/client";
import client from "../../client";
import {objectToUrlString} from "../../../common/utils/http";
import type {AsyncAxiosResponse} from "../../../types/common";
import {getTabs} from "../MainWindow";

enum EFavouritesWindow {
  BATCH = "favourites/batch",
}

export const BATCH_COUNT = 10;

export async function getFavouritePageLoader() {
  return {
    tabsLoader: await getTabs(),
    favouritesBatchLoader: await getFavouritesBatch({count: BATCH_COUNT}),
  };
}

export async function getFavouritesBatch(
  params: Paths.ApiFavouritesBatch.Get.QueryParameters,
): AsyncAxiosResponse<Components.Schemas.FWBatch, any> {
  const queryParameters = objectToUrlString(params);
  return await client.get(`${EFavouritesWindow.BATCH}?${queryParameters}`);
}
