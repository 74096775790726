import reduce from "lodash.reduce";

export function objectToUrlString(object: Record<string, any>) {
  return reduce(
    object,
    (result: string, value: string | number | boolean, key) => {
      if (value !== null && value !== undefined) {
        if (Array.isArray(value)) {
          result += reduce(
            value,
            (result1: string, value1: string | number | boolean) => {
              if (value1 !== null && value1 !== undefined) {
                result1 += `${encodeURI(key)}=${encodeURIComponent(value1)}&`;
                return result1;
              }

              return result1;
            },
            "",
          );
        } else {
          result += `${encodeURI(key)}=${encodeURIComponent(value)}&`;
        }

        return result;
      }

      return result;
    },
    "",
  ).slice(0, -1);
}

export function downloadFile(url: string, fileName = "") {
  if (!url) return;
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  link.setAttribute("target", "_blank");
  link.setAttribute("rel", "noreferrer noopener");
  /* IE */
  link.style.display = "none";
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
}
