import {PERMISSION_FIELD_MAP} from "common/constants/constants";
import {type Components} from "types/client";

export function clearPermissions() {
  Object.values(PERMISSION_FIELD_MAP).forEach(permission => {
    window.sessionStorage.removeItem(permission.toString());
  });
}

export function savePermissionsToStorage(
  permissions: Components.Schemas.Permissions,
) {
  Object.keys(permissions).forEach((fieldName: string) => {
    const key = (PERMISSION_FIELD_MAP as any)[fieldName];
    if (typeof key === "number") {
      window.sessionStorage.setItem(
        key.toString(),
        `${(permissions as any)[fieldName] ? 1 : 0}`,
      );
    }
  });
}
