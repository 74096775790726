import {type AxiosResponse} from "axios";
import client from "../../client";
import {type Components} from "../../../types/client";

export enum EProfileUrl {
  PROFILE = "/profile",
}

export const getUserProfileData = async (): Promise<
  AxiosResponse<Components.Schemas.PWProfile> | string
> => {
  try {
    return await client.get(EProfileUrl.PROFILE);
  } catch (error) {
    const result = error instanceof Error ? error.message : String(error);

    return result;
  }
};
