import {type AxiosResponse} from "axios";
import client from "../../client";
import {type Components} from "../../../types/client";

export enum ELeaderboardUrl {
  LEADERBOARD = "/leaderboard",
}

export const getLeaderboardData = async (): Promise<
  AxiosResponse<Components.Schemas.Leaderboard> | string
> => {
  try {
    return await client.get(ELeaderboardUrl.LEADERBOARD);
  } catch (error) {
    return error instanceof Error ? error.message : String(error);
  }
};
