import {type ReactNode, createContext, useState, useEffect} from "react";
import jwt_decode from "jwt-decode";
import {authLogin, authRefresh} from "../api/services/Auth/Auth";
import {type Components} from "types/client";
import {clearPermissions, savePermissionsToStorage} from "common/utils/auth";
import {PERMISSION_FIELD_MAP} from "common/constants/constants";
import {ECommonEvents} from "common/constants/enums";

interface AuthProvider {
  children: ReactNode;
}

interface IAuthContext {
  user: unknown | null;
  login: (body: any) => Promise<void>;
  refresh: (params: any) => Promise<void>;
  logout: () => Promise<void>;
  permissions: Components.Schemas.Permissions | null;
}

const AuthContext = createContext<IAuthContext | null>(null);
export const AuthContextProvider = ({children}: AuthProvider) => {
  const [user, setUser] = useState(() => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (accessToken !== null) {
        return jwt_decode(accessToken);
      }

      return null;
    } catch (error) {
      return null;
    }
  });

  const [permissions, setPermissions] =
    useState<Components.Schemas.Permissions | null>(() => {
      const result: Record<string, boolean> = {};

      Object.keys(PERMISSION_FIELD_MAP).forEach(fieldName => {
        const itemValue = window.sessionStorage.getItem(
          (PERMISSION_FIELD_MAP as any)[fieldName].toString(),
        );

        if (itemValue) {
          result[fieldName] = Boolean(+itemValue);
        }
      });

      return result;
    });

  useEffect(() => {
    const resetPermissions = (e: any) => {
      const result =
        (
          e as {
            permissions: Components.Schemas.Permissions;
          }
        ).permissions ?? null;

      setPermissions(state => {
        if (!state) {
          return result;
        } else {
          return result ? {...state, ...result} : result;
        }
      });
    };
    document.addEventListener(
      ECommonEvents.RESET_PERMISSIONS,
      resetPermissions,
    );

    return () => {
      document.removeEventListener(
        ECommonEvents.RESET_PERMISSIONS,
        resetPermissions,
      );
    };
  }, []);

  const handleSetPermissions = (
    permissions: Components.Schemas.Permissions,
  ) => {
    savePermissionsToStorage(permissions);

    setPermissions(permissions);
  };

  const login = async (body: any) => {
    const response = await authLogin(body);

    if (response.data.accessToken && response.data.refreshToken) {
      window.localStorage.setItem("accessToken", response.data.accessToken);
      window.localStorage.setItem("refreshToken", response.data.refreshToken);

      setUser(jwt_decode(response.data.accessToken));
    }

    if (response.data.accessToken && response.data.permissions) {
      handleSetPermissions(response.data.permissions);
    }
  };

  const refresh = async (params: any) => {
    try {
      const response = await authRefresh(params);

      if (response.data.accessToken && response.data.refreshToken) {
        window.localStorage.setItem("accessToken", response.data.accessToken);
        window.localStorage.setItem("refreshToken", response.data.refreshToken);

        setUser(jwt_decode(response.data.accessToken));
      }

      if (response.data.accessToken && response.data.permissions) {
        handleSetPermissions(response.data.permissions);
      }
    } catch (e) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      clearPermissions();

      setUser(null);
      setPermissions(null);
    }
  };

  const logout = async () => {
    localStorage.clear();
    clearPermissions();

    setUser(null);
    setPermissions(null);
  };

  return (
    <AuthContext.Provider value={{user, login, refresh, logout, permissions}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
