export enum ERoute {
  MAIN = "/",
  SIGN_IN = "/signIn",
  CATEGORIES = "/categories/:id",
  PROFILE = "/profile",
  PROFILE_PERSONAL = "/profile/personal",
  PROFILE_SECURITY = "/profile/security",
  PROFILE_RATING = "/profile/rating",
  UPLOADED = "/uploaded",
  FAVOURITE = "/favourite",
}
