import AuthContext from "provider/AuthProvider";
import {useContext} from "react";

export function useAuthContext() {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error(
      "useAuthContext has to be used within <AuthContext.Provider>",
    );
  }

  return authContext;
}
