import {Navigate, Outlet} from "react-router-dom";
import {ERoute} from "../configs/ERoute";
import {useAuthContext} from "common/hooks/useAuthContext";

export const ProtectedRoute = () => {
  const {user} = useAuthContext();

  if (user === null) {
    return <Navigate to={ERoute.SIGN_IN} />;
  }

  return <Outlet />;
};
