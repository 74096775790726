export enum EIconNames {
  ARROW_RIGHT = "arrowRight",
  ARROW_UP = "arrowUp",
  CHECKBOX = "checkbox",
  FLAG = "flag",
  FLAG_FILLED = "flagFilled",
  BIN = "bin",
  CROSS = "cross",
  CHAIN = "chain",
  EXCLAMATION = "exclamation",
  SPINNER = "loader",
  UPLOAD = "upload",
  LOGOUT = "logout",
  PROFILE = "profile",
  UPLOAD_SIGN = "upload_sign",
  PPTX = "pptx",
  STAR = "star",
  LOCK = "lock",
  STAR_OFF = "star_off",
  ARROW_LEFT = "arrowLeft",
  INFO_CIRCLE = "info_circle",
}

export enum ESizes {
  XL = "xl",
  L = "l",
  M = "m",
  S = "s",
  XS = "xs",
}

export enum EButtonTypes {
  GHOST = "ghost",
  BORDER = "border",
  FILL = "fill",
}

export enum EButtonStyles {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  LABEL = "label",
}

export enum EProfileMenuItems {
  PROFILE = "profile",
  UPLOADED = "uploaded",
}

export enum EPermissions {
  UPLOAD,
  VIEW_USER_RATING,
  REVIEWED_FILTER,
  DELETE_PRESENTATION,
  EXPORT,
}

export enum ECommonEvents {
  RESET_PERMISSIONS = "reset_permissions",
}

export enum EMetaDataType {
  BOOLEAN = "upw.mbool",
  STRING = "upw.mstr",
  DATE = "upw.mdate",
  ENUM = "upw.menum",
  FLOAT = "upw.mfloat",
  INTEGER = "upw.mint",
}
